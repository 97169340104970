import React from "react";
import useTranslations from "../../../components/useTranslations";
import Footer from "../../../components/Footer/Footer";
import { graphql } from "gatsby";
import FaqLeftNav from "../../../components/TwoColumnLeftNav/FaqLeftNav";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { LocaleContext } from "../../../components/layout";

const Category = ({ data: { allMdx } }) => {
	const { faq } = useTranslations();

	const { locale } = React.useContext(LocaleContext);
	if (typeof window !== "undefined") {
		if (locale === "ja") {
			window.location.href = "/20210729/";
		} else {
			window.location.href = "/en/20210729/";
		}
	}
	return (
		<>
			<article className="category-page two-column-left-nav">
				<FaqLeftNav />
				<section className="main-content">
					<section className="main-content">
						<h1 className={"large bold"}>{faq} </h1>
						<div className="content-container">
							<ul className="post-list between wrap">
								{allMdx.edges.map(({ node: post, index }) => (
									<li
										className={"faq-list"}
										key={`${post.frontmatter.title}-${post.fields.locale}`}
									>
										<h2 className="faq-title">Q.{post.frontmatter.title}</h2>
										<MDXRenderer>{post.body}</MDXRenderer>
									</li>
								))}
							</ul>
						</div>
					</section>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Category;

export const query = graphql`
	query categoryFaq($locale: String!) {
		allMdx(
			filter: {
				fileAbsolutePath: { regex: "/(faq)/.*\\\\.mdx$/" }
				fields: { locale: { eq: $locale } }
			}
			sort: { fields: frontmatter___sort }
		) {
			edges {
				node {
					frontmatter {
						title
					}
					body
					fields {
						locale
					}
				}
			}
		}
	}
`;
